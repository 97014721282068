@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "./react-toasify/main.scss";
@import "react-quill/dist/quill.snow.css";

.box {
  display: flex;
  width: fit-content;
  position: relative;
}

.resizable-container {
  position: relative !important;
  background-color: transparent;
  // border: dashed red 2px;
  pointer-events: all;
}

.draghandle {
  position: absolute !important;
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
  background: rgba(179, 179, 179, 0.328);
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(179, 179, 179, 0.35);
  }

  &:focus {
    background: rgba(179, 179, 179, 0.363);
  }
}
