@import "../../../styles/theme";

.creator-card {
  display: flex;
  align-items: stretch;
  background-color: $asset-card-header-bg;
  border-radius: 0.5rem;
  padding: 0.5rem;
  max-width: 600px;
}
