$source-card-header-bg: #0b1755;
$source-card-body-bg: #3f4b89;
$source-card-footer-bg: #0b1755;
$source-card-btn-action-bg: #8282857f;
$source-card-btn-follow-bg: #ff0303ef;

$asset-card-header-bg: #d9d9d9;
$asset-card-title-bg: #828285af;
$asset-card-body-bg: #1c1c1e;
$asset-card-footer-bg: #d9d9d9;

$alert-folder-card-header-bg: #1c1c1e;
$alert-folder-card-title-bg: #828285af;
$alert-folder-card-body-bg: #1c1c1e;
$alert-folder-card-footer-bg: #1c1c1e;

$channel-card-header-bg: #1c1c1e;
$channel-card-title-bg: #828285af;
$channel-card-body-bg: #1c1c1e;
$channel-card-footer-bg: #1c1c1e;

$widget-card-header-bg: #0b1755;
$widget-card-body-bg: #0b1755;
$widget-card-footer-bg: #0b1755;
