.emoji-picker {
  z-index: 100;
  position: absolute;
  padding-top: 2px;
  width: min(100vw, 290px);
  font-size: 13px;
  margin-top: 10px;

  border-radius: 10px;
  background: rgb(255, 255, 255, 0.6);
  backdrop-filter: blur(40px);
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.05);

  /* Disable text selection */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version */

  text-align: left;
}

.emoji-picker.emoji-dark-mode {
  background: rgb(0, 0, 0, 0.6);
  backdrop-filter: blur(40px);
  color: #f8fafc;
}

.emoji-search.emoji-dark-mode > input {
  color: #f8fafc;
}

.emoji-search.emoji-dark-mode > input::placeholder {
  color: rgb(255, 255, 255, 0.6);
}

.emoji-picker > .emoji-container {
  height: 300px;
  overflow-y: scroll;
}

.emoji-picker > .emoji-container::-webkit-scrollbar {
  width: 10px;
}

.emoji-picker > .emoji-container::-webkit-scrollbar-track {
  background: transparent;
}

.emoji-picker > .emoji-container::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.2);
  border-radius: 5px;
}

.emoji-picker.emoji-dark-mode > .emoji-container::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255, 0.5);
}

.emoji-picker > .emoji-container > .top-bar {
  display: flex;
  align-items: center;
}

.emoji-picker > .emoji-container > .top-bar > :first-child {
  flex-grow: 2;
}

.emoji-picker > .emoji-container > .top-bar > :last-child {
  padding: 10px;
  border-radius: 10px;
}

.emoji-picker > .emoji-container > .top-bar > :last-child:hover {
  cursor: pointer;
  background: rgb(0, 0, 0, 0.08);
}

.emoji-picker.emoji-dark-mode > .emoji-container > .top-bar > :last-child:hover {
  background: rgb(255, 255, 255, 0.2);
}

.emoji-picker > .emoji-container > .emoji-body {
  padding-inline: 10px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
}

.emoji-grid {
  display: grid;
  grid-gap: 2px;
  grid-template-columns: repeat(auto-fill, minmax(35px, 1fr));
}

.emoji-grid > div {
  height: 35px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.emoji-grid > div:hover {
  cursor: pointer;
}

.emoji-picker > .emoji-container > .emoji-body > .emoji-group > :first-child {
  /*text-transform: uppercase;*/
  padding: 5px 0px;
}

.emoji-picker > .emoji-container > .emoji-body > .emoji-group > .emoji-recently-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emoji-picker > .emoji-container > .emoji-body > .emoji-group > .emoji-recently-title > :last-child {
  font-size: 12px;
  font-weight: 300;
  padding: 3px 5px;
  border-radius: 5px;
}

.emoji-picker > .emoji-container > .emoji-body > .emoji-group > .emoji-recently-title > :last-child:hover {
  background: rgb(0, 0, 0, 0.1);
  cursor: pointer;
}

.emoji-picker.emoji-dark-mode
  > .emoji-container
  > .emoji-body
  > .emoji-group
  > .emoji-recently-title
  > :last-child:hover {
  background: rgb(255, 255, 255, 0.1);
}

.emoji-search {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 5px;
  border: 1px solid rgb(0, 0, 0, 0.3);
  border-radius: 8px;
  margin: 10px;
  margin-right: 5px;
}

.emoji-search.emoji-dark-mode {
  fill: rgb(255, 255, 255, 0.8);
  border: 1px solid rgb(255, 255, 255, 0.5);
}

.emoji-search > input {
  outline: none;
  background: transparent;
  width: 100%;
  border: 0;
  font-size: 12px;
}

.emoji-search > input::placeholder {
  color: rgb(0, 0, 0, 0.7);
}

.emoji-picker > .emoji-footer {
  -webkit-box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.1);
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.emoji-picker > .emoji-footer > div {
  fill: rgb(0, 0, 0, 0.4);
  height: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emoji-picker > .emoji-footer.emoji-dark-mode > div {
  fill: rgb(255, 255, 255, 0.8);
}

.emoji-picker > .emoji-footer > div:hover {
  fill: currentColor;
  cursor: pointer;
}

/*
 * Emoji Selector Mobile version
 *
 */

.emoji-mobile-picker {
  z-index: 1001;
  /* position: absolute; */
  display: flex;
  align-items: center;
  /* width: 100vw; */
  height: 100vh;
  /* left: 0; */
  /* top: 0; */
  width: 100%;
  bottom: 0;

  background: rgb(0, 0, 0, 0.4);
  /* overflow: hidden; */

  /* Disable text selection */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version */
}

.emoji-mobile-picker.emoji-mobile-dark {
  color: white;
}

.emoji-mobile-picker > div {
  background: white;
  margin-inline: auto;
  width: min(100%, 500px);
  height: fit-content;
}

.emoji-mobile-picker.emoji-mobile-dark > div {
  background: #252525;
}

.emoji-mobile-picker > div > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid rgb(0,0,0,0.1); */
}

.emoji-mobile-picker.emoji-mobile-dark > div > div:nth-child(1) {
  /* border-bottom: 1px solid rgb(255,255,255,0.1); */
}

.emoji-mobile-picker > div > div:nth-child(1) > div {
  flex: 1;
  text-align: center;
}

.emoji-mobile-picker > div > div:nth-child(1) > :last-child {
  display: flex;
  justify-content: flex-end;
  color: red;
}

.emoji-mobile-picker > div > div:nth-child(1) > :last-child > div {
  padding: 15px;
}

.emoji-mobile-picker > div > div:nth-child(1) > :last-child > div:hover {
  cursor: pointer;
}

.emoji-mobile-category {
  display: flex;
  align-items: center;
  margin-top: 5px;
  flex-wrap: wrap;
  margin-bottom: 5px;
  border-bottom: 1px solid rgb(0, 0, 0, 0.1);
}

.emoji-mobile-category.emoji-mobile-dark {
  /* border-bottom: 1px solid rgb(255,255,255,0.1); */
}

.emoji-mobile-category > div {
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  fill: black;
}

.emoji-mobile-category.emoji-mobile-dark > div {
  fill: rgb(255, 255, 255, 0.8);
}

.emoji-mobile-category > div:hover {
  fill: currentColor;
  cursor: pointer;
}

.emoji-mobile-picker > div > div:nth-child(3) {
  padding: 10px;
  border-bottom: 1px solid rgb(0, 0, 0, 0.1);
}

.emoji-mobile-picker.emoji-mobile-dark > div > div:nth-child(3) {
  border-bottom: 1px solid rgb(255, 255, 255, 0.1);
}

.emoji-mobile-picker > div > div:nth-child(3) > input {
  background: transparent;
  margin: 0;
  outline: none;
  border: 0;
  font-size: 16px;
  width: 100%;
}

.emoji-mobile-picker.emoji-mobile-dark > div > div:nth-child(3) > input {
  color: rgb(255, 255, 255, 0.8);
}

.emoji-mobile-picker > div > div:nth-child(4) {
  height: 70vh;
  overflow-y: scroll;
  font-weight: 300;
}

.emoji-mobile-picker > div > div:nth-child(4) > :first-child > :first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emoji-mobile-picker > div > div:nth-child(4) > :first-child > :first-child > :last-child {
  padding: 3px 5px;
  border-radius: 3px;
}

.emoji-mobile-picker.emoji-mobile-dark > div > div:nth-child(4) > :first-child > :first-child > :last-child:hover {
  background: rgb(255, 255, 255, 0.1);
}

.emoji-mobile-picker > div > div:nth-child(4) > :first-child > :first-child > :last-child:hover {
  cursor: pointer;
  background: rgb(0, 0, 0, 0.1);
}

.emoji-mobile-picker.emoji-mobile-dark > div > div:nth-child(4) {
  color: rgb(255, 255, 255, 0.8);
}

.emoji-mobile-picker.emoji-mobile-dark > div > div:nth-child(4)::-webkit-scrollbar {
  width: 10px;
}

.emoji-mobile-picker.emoji-mobile-dark > div > div:nth-child(4)::-webkit-scrollbar-track {
  background: #313131;
}

.emoji-mobile-picker.emoji-mobile-dark > div > div:nth-child(4)::-webkit-scrollbar-thumb {
  background: #202020;
  border-radius: 10px;
}

.emoji-mobile-picker > div > div:nth-child(4) > div:first-child > div:first-child {
  border-top: 0;
}

.emoji-mobile-picker > div > div:nth-child(4) > .emoji-group > div:first-child {
  padding: 10px 15px;
  background: #f7f6f3;
  border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  border-top: 1px solid rgb(0, 0, 0, 0.05);
}

.emoji-mobile-picker.emoji-mobile-dark > div > div:nth-child(4) > .emoji-group > div:first-child {
  border-bottom: 1px solid rgb(255, 255, 255, 0.1);
}

.emoji-mobile-picker.emoji-mobile-dark > div > div:nth-child(4) > .emoji-group > div:first-child {
  background: #202020;
}

.emoji-mobile-picker > div > div:nth-child(4) > .emoji-group > .emoji-grid {
  grid-template-columns: repeat(auto-fill, minmax(45px, 1fr));
  gap: 0;
  margin: 10px 0;
}

.emoji-mobile-picker > div > div:nth-child(4) > .emoji-group > .emoji-grid > div {
  border-radius: 10px;
  font-size: 30px;
  height: 45px;
}

.emoji-mobile-picker > div > div:nth-child(4) > .emoji-group > .emoji-grid > div:hover {
  cursor: pointer;
  background: rgb(0, 0, 0, 0.1);
}

@media only screen and (max-width: 500px) {
  .emoji-mobile-picker > div {
    height: 100vh;
  }

  .emoji-mobile-picker > div > div:nth-child(4) {
    height: calc(100vh - 100px);
  }
}
