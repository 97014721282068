.action-button-left {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.action-button-right {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.channel-carousel-wrapper {
  transition: all 0.5s;
}

.detail-page-replay-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: #232323dd;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon-replay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #454545aa;
    font-size: 2rem;
    color: #f2f2f2;
  }

  .text-replay {
    font-size: 1.25rem;
    color: #f2f2f2;
    margin-top: 0.5rem;
    font-weight: 500;
  }
}

.rich-text-viewer {
  p {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.5;
  }
}

.note-item-enter {
  opacity: 0;
}

.note-item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.note-item-exit {
  opacity: 1;
}

.note-item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
