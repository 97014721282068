html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  width: 100%;
  height: 100%;
  background-color: white;
}

.app-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: rgb(18, 18, 18);
}

a {
  text-decoration: none;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@media screen and (min-width: 680px) {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0003;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
  }

  ::-webkit-scrollbar-track {
    border-radius: 6px;
  }
}

@media screen and (max-width: 768px) {
  * {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
