@import "../../../styles/theme";

.asset-card-container {
  .asset-card {
    position: relative;
    background-color: #32353b;

    .asset-card-header {
      border-radius: 8px 8px 0 0;
      display: flex;
      align-items: center;
      background: $asset-card-header-bg;
      color: #fff;
      padding: 4px 4px;
      font-size: 0.75rem;
      min-height: 32px;
    }

    .asset-card-image {
      position: absolute;
      top: 7px;
      right: 7px;
      border-radius: 8px;

      img {
        width: 132px;
        height: 170px;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .asset-card-star {
      position: absolute;
      top: 18px;
      right: 18px;
    }

    .asset-card-body {
      display: flex;
      flex-direction: column;
      height: 152px;
      background-color: $asset-card-body-bg;

      .asset-card-desc-title {
        padding: 8px;
        font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
        font-size: 1rem;
        line-height: 1.5;
        color: #fff;
        background: $asset-card-title-bg;
        height: calc(1.5rem + 16px);

        a {
          color: #1890ff;
          text-decoration: none;
        }
      }
      .asset-card-desc-short {
        flex: 1;
        padding: 3px 7px 5px 7px;
        color: #ffffff;
        font-size: 1rem;
        font-weight: 500;
      }

      .asset-card-desc-note {
        color: #fff;
        padding: 4px 8px;
      }
    }

    &-asset-folder {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #353535;
      &__left {
        padding: 5px;
        display: flex;
        align-items: center;
        color: #fff;
        svg {
          margin-right: 8px;
        }
      }

      &__right {
        display: flex;
        align-items: center;
        color: #fff;
        padding: 5px;
        svg {
          margin-left: 4px;
        }
      }
    }

    .asset-card-action {
      border-top: 1px solid #444444;
      border-radius: 0 0 8px 8px;
      padding: 0px 4px 0px 12px;
      background-color: $asset-card-footer-bg;
      display: flex;
      align-items: center;
      position: relative;
      min-height: 40px;

      &__left {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      &__right {
        display: flex;
        align-items: center;
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #e2e2e2;
      }
    }
  }
}
