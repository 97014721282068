.location-auth-code {
  display: flex;
  justify-content: center;

  --ReactInputVerificationCode-itemWidth: 50px;
  --ReactInputVerificationCode-itemHeight: 52px;
  --ReactInputVerificationCode-itemSpacing: 8px;

  .ReactInputVerificationCode__item {
    font-size: 1.5rem;
    font-weight: 500;
    color: #e2e2e2;
    border-radius: 4px;
    border: 2px solid #d6d6d6;
    box-shadow: none;
  }

  .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    border: 2px solid #1890ff;
  }
}
