.main-content-wrapper {
  position: relative;
  padding: 2rem;
}

.page-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.main-page-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.bottom-navigation-bar {
  width: 100%;
  border-top: 2px solid #585858;
  background-color: #252525;
  z-index: 999;
  position: absolute;
  left: 0;
  transition: all 0.5s;

  .MuiBottomNavigationAction-label {
    margin-top: 0.25rem;
  }
}
.auth-page-wrapper {
  background-color: black;
}
// .profile-menu {
//   position: absolute;
//   top: 15px;
//   left: 15px;
//   z-index: 10;
// }

// .source-info {
//   position: absolute;
//   top: 15px;
//   right: 15px;
//   z-index: 10;
// }

// .video-player-container {
//   flex: 1;
//   position: relative;
// }

// .video-player-wrapper {
//   position: absolute;
//   width: 100%;
//   bottom: 0;
//   padding: 10px;
// }

// .bottom-navigation-bar {
//   width: 100%;
// }

// .subpage-container {
//   position: relative;
//   width: 100%;
//   height: 100%;
// }

// .video-actions {
//   position: absolute;
//   bottom: 60px;
//   right: 10px;
//   z-index: 10;
// }

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.relative {
  position: relative;
}

.cover-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.logout-guard-view {
  position: absolute;
  transition: all 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  width: 100%;
  height: 0;
  overflow: auto;
  background-color: #e0e0e0;
  will-change: height;
  opacity: 0;
  max-width: 100%;
  max-height: 160px;
  box-shadow: 0px 16px 16px -10px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  overflow-y: hidden;
}

.resize_container {
  position: relative !important;
  background-color: transparent;
  border: dashed red 2px;
  pointer-events: all;

  &_preview {
    border: none;
  }
}
