.guard-div-root {
  width: 100%;
  height: 120px;
  background: #e8e8e8;
  display: flex;
  flex-direction: column;
  box-shadow: 6px 6px 6px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  position: relative;
  overflow-y: hidden;
}

.guard-div-label {
  font-size: 18px;
  font-weight: 600;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  color: #353535;
}

.guard-div-actions {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 10px;
  overflow-y: hidden;
  color: #353535;
}
