.topbar-navlink {
  position: relative;
  font-size: 0.875rem;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
  color: rgba(255, 255, 255, 0.6);
  margin: 0.2rem;
  border-bottom: 2px solid transparent;

  &::after {
    position: absolute;
    left: 0;
    bottom: -2px;
    content: "";
    width: 0px;
    height: 2px;
    background-color: #fff;
    transition: all 0.5s;
  }

  &:not(.disabled) {
    &:hover,
    &.active {
      background-position: 100% 100%; /*OR bottom right*/
      background-size: 100% 2px;
      color: #fff;

      &::after {
        width: 100%;
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }
}
