.new-creator-cards:hover > .new-creator-card:not(:hover) .new-creator-card__background {
  filter: brightness(0.5) saturate(0) contrast(1) blur(4px);
}

.new-creator-card {
  &__background {
    background-size: cover;
    background-position: center;
    border-radius: 0.75rem;
    bottom: 0;
    filter: brightness(0.8) saturate(1.3) contrast(0.8);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center;
    transform: scale(1) translateZ(0);
    transition: filter 200ms linear, transform 200ms linear;
  }

  &__content {
    transform-origin: center;
    transform: scale(1) translateZ(0);
    transition: filter 200ms linear, transform 200ms linear, bottom 200ms linear;
  }

  &:hover {
    .new-creator-card__background {
      transform: scale(1.025) translateZ(0);
    }
    .new-creator-card__content {
      transform: scale(1.025) translateZ(0);
      bottom: 10%;
    }
  }
}
